import React from "react";
import s from "./CardDescription.module.css"

const CardDescription = (props) => {
	return (
		<div className={s.description_wrapper}>
			<div>
				<div>{props.text}</div>
				<div>{props.sub_description}</div>
			</div>
			<div>
				<div>{props.accessories}</div>
				<div>{props.lining}</div>
				<div>{props.material}</div>
			</div>
			<div>{props.size}</div>
			<div>{props.weight}</div>
		</div>
	)
}

export default CardDescription;