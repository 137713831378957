import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import s from './Slider.module.css'

const Slider = (props) => {
	let slidsElements = props.slider.map(p =>
		<SwiperSlide>
			<div className={s.slide_wrapper} >
				<div className={s.image_wrapper}>
					<img src={p.slider_image} className={s.slide_image} alt="" width="100%" />
				</div>
				<div className={s.slide_description}>
					<h1 className={s.description_title}>
						<span>{p.description_title}</span>
					</h1>
					<h4 className={s.description_subtitle}>{p.description_subtitle}</h4>
				</div>
			</div>
		</SwiperSlide>
	)
	return (
		<Swiper
			modules={[Pagination, Scrollbar, A11y, Autoplay]}
			autoplay={{ delay: 5000 }}                 
			loop={true}
			spaceBetween={0}
			slidesPerView={1}
			onSlideChange={() => console.log('slide change')}
			navigation={true}
			scrollbar={{ draggable: true }}
			onSwiper={(swiper) => console.log(swiper)}
		>
			{slidsElements}
		</Swiper>
	)
}
export default Slider;