import React from "react";
import s from './Category.module.css'
import { NavLink } from "react-router-dom";

const Category = (props) => {
    return(
        
        <NavLink to={props.button_link}>
            <div className={s.category_wrapper}>
                    <div className={s.category_title}>{props.categories_title}</div>
                    <div className={s.opacity_block}></div>
                    <img className={s.category_image} src={props.background_image} alt="" />
            </div>
        </NavLink>
    )
}
export default Category;