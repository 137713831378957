import sertificate_5000 from '../image/Sertificates/sertificate_5000.webp';
import sertificate_7000 from '../image/Sertificates/sertificate_7000.webp';
import sertificate_10000 from '../image/Sertificates/sertificate_10000.webp';
import sertificate_12000 from '../image/Sertificates/sertificate_12000.webp';
import sertificate_15000 from '../image/Sertificates/sertificate_15000.webp';
import sertificate_20000 from '../image/Sertificates/sertificate_20000.webp';

const SET_SERTIFICATE = "SET-SERTIFICATE";

let initialState = {
	sertificates_new: [
		{
			id: 0,
			card_name: 'СЕРТИФИКАТ',
			price: 5000,
			price_active: "",
			count: 1,
			card_image: sertificate_5000,
			description: [
				`1.	Номинал сертификата активен год с момента приобретения, его не обязательно тратить единоразово;`,
				`2.	Гарантия и возврат товаров, приобретенных с использованием Подарочного сертификата, осуществляется в общем порядке, предусмотренном действующим законодательством РФ;`,
				`3.	Обязательно убедитесь, что ввели верную информацию получателя при оплате.`,
			],
			codes: [
				{ code: "36Z050lC" },
				{ code: "ty3coBhA" },
				{ code: "x6pDxZL9" },
				{ code: "1Q6WLkRA" },
				{ code: "2FlaRHJK" },
			]
		},
		{
			id: 1,
			card_name: 'СЕРТИФИКАТ',
			price: 7000,
			price_active: "",
			count: 1,
			card_image: sertificate_7000,
			description: [
				`1.	Номинал сертификата активен год с момента приобретения, его не обязательно тратить единоразово;`,
				`2.	Гарантия и возврат товаров, приобретенных с использованием Подарочного сертификата, осуществляется в общем порядке, предусмотренном действующим законодательством РФ;`,
				`3.	Обязательно убедитесь, что ввели верную информацию получателя при оплате.`,
			],
			codes: [
				{ code: "36Z050lC" },
				{ code: "ty3coBhA" },
				{ code: "x6pDxZL9" },
				{ code: "1Q6WLkRA" },
				{ code: "2FlaRHJK" },
			]
		},
		{
			id: 2,
			card_name: 'СЕРТИФИКАТ',
			price: 10000,
			price_active: "",
			count: 1,
			card_image: sertificate_10000,
			description: [
				`1.	Номинал сертификата активен год с момента приобретения, его не обязательно тратить единоразово;`,
				`2.	Гарантия и возврат товаров, приобретенных с использованием Подарочного сертификата, осуществляется в общем порядке, предусмотренном действующим законодательством РФ;`,
				`3.	Обязательно убедитесь, что ввели верную информацию получателя при оплате.`,
			],
			codes: [
				{ code: "36Z050lC" },
				{ code: "ty3coBhA" },
				{ code: "x6pDxZL9" },
				{ code: "1Q6WLkRA" },
				{ code: "2FlaRHJK" },
			]
		},
		{
			id: 3,
			card_name: 'СЕРТИФИКАТ',
			price: 12000,
			price_active: "",
			count: 1,
			card_image: sertificate_12000,
			description: [
				`1.	Номинал сертификата активен год с момента приобретения, его не обязательно тратить единоразово;`,
				`2.	Гарантия и возврат товаров, приобретенных с использованием Подарочного сертификата, осуществляется в общем порядке, предусмотренном действующим законодательством РФ;`,
				`3.	Обязательно убедитесь, что ввели верную информацию получателя при оплате.`,
			],
			codes: [
				{ code: "36Z050lC" },
				{ code: "ty3coBhA" },
				{ code: "x6pDxZL9" },
				{ code: "1Q6WLkRA" },
				{ code: "2FlaRHJK" },
			]
		},
		{
			id: 4,
			card_name: 'СЕРТИФИКАТ',
			price: 15000,
			price_active: "",
			count: 1,
			card_image: sertificate_15000,
			description: [
				`1.	Номинал сертификата активен год с момента приобретения, его не обязательно тратить единоразово;`,
				`2.	Гарантия и возврат товаров, приобретенных с использованием Подарочного сертификата, осуществляется в общем порядке, предусмотренном действующим законодательством РФ;`,
				`3.	Обязательно убедитесь, что ввели верную информацию получателя при оплате.`,
			],
			codes: [
				{ code: "36Z050lC" },
				{ code: "ty3coBhA" },
				{ code: "x6pDxZL9" },
				{ code: "1Q6WLkRA" },
				{ code: "2FlaRHJK" },
			]
		},
		{
			id: 5,
			card_name: 'СЕРТИФИКАТ',
			price: 20000,
			price_active: "",
			count: 1,
			card_image: sertificate_20000,
			description: [
				`1.	Номинал сертификата активен год с момента приобретения, его не обязательно тратить единоразово;`,
				`2.	Гарантия и возврат товаров, приобретенных с использованием Подарочного сертификата, осуществляется в общем порядке, предусмотренном действующим законодательством РФ;`,
				`3.	Обязательно убедитесь, что ввели верную информацию получателя при оплате.`,
			],
			codes: [
				{ code: "36Z050lC" },
				{ code: "ty3coBhA" },
				{ code: "x6pDxZL9" },
				{ code: "1Q6WLkRA" },
				{ code: "2FlaRHJK" },
			]
		},
		{},
		{},
	],
	sertificate_selected: {
		id: 0,
		card_name: "",
		price: 0,
		count: 1,
		card_image: sertificate_5000,
		description: [
			`1.	Номинал сертификата активен год с момента приобретения, его не обязательно тратить единоразово;`,
			`2.	Гарантия и возврат товаров, приобретенных с использованием Подарочного сертификата, осуществляется в общем порядке, предусмотренном действующим законодательством РФ;`,
			`3.	Обязательно убедитесь, что ввели верную информацию получателя при оплате.`,
		],
		code: "",
	},
	sertificates_code_active: [],
	sertificates_code_used: [],
	link: "/sertificates"
}

const sertificatesReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SERTIFICATE:
			state.sertificate_selected.id = action.new_id;
			state.sertificate_selected.card_name = action.new_name;
			state.sertificate_selected.price = action.new_price;
			state.sertificate_selected.card_image = action.new_image;
			state.sertificate_selected.code = action.new_code;
			return state;
		default:
			return state;
	}
};
export const setSertificateAC = (id, name_caption, price, image, code) => ({
	type: SET_SERTIFICATE,
	new_id: id,
	new_name: name_caption,
	new_price: price,
	new_image: image,
	new_code: code,
})
export default sertificatesReducer;
