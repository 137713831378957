import React from "react";
import CartPage from "./CartPage";
import { plusCartCountAC, delCardIdAC, plusModelCountAC, resultSummFuncAC } from "../../redux/catalog-reducer";

const CartPageContainer = (props) => {
	let state = props.store.getState();
	let setCountModel = (count_cart, idArr) => {
		let action = plusCartCountAC(count_cart, idArr);
		props.store.dispatch(action);
	}
	let delCardOfCart = (cardIdCount) => {
		let action = delCardIdAC(cardIdCount);
		props.store.dispatch(action);
	}
	let plusModelCount = (count_card) => {
		let action = plusModelCountAC(count_card);
		props.store.dispatch(action);
	}
	let summResulCart = (summ) => {
		let action = resultSummFuncAC(summ);
		props.store.dispatch(action);
	}
	return (
		<CartPage cart_list={state.catalog.cart_list} setCountModel={setCountModel} delCardOfCart={delCardOfCart} plusModelCount={plusModelCount} summResulCart={summResulCart} summ={state.catalog.result_summ}/>
	)
}
export default CartPageContainer;