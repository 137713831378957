import React from "react";
import s from "./Color.module.css"

const Color = (props) => {
	let style;
	if (props.new_index === props.index) {
		style = {
			border: "3px solid gold",
			width: "94px",
			height: "94px",
		}
	}
	let changeColor = () => {
		props.changeColor(props.index)
	}
	return (
		<button className={s.model_wrapper} onClick={changeColor}>
			<div>{props.name}</div>
			<img className={s.image_model} src={props.image} style={style} alt="" />
		</button>
	)
}

export default Color;