const UPDATE_SELECTOR_MENU = 'UPDATE-SELECTOR-MENU';
const UPDATE_STATE_MENU = 'UPDATE-STATE-MENU'
let initialState = {
	menu_selector: 'Header_menu_burgerClose__m+Il6',
	menu_state: true,
}
const headerReducer = (state = initialState, action) => {

	switch (action.type) {
		case UPDATE_SELECTOR_MENU:
			state.menu_selector = action.newMenuSelector;
			return state;
		case UPDATE_STATE_MENU:
			state.menu_state = action.newMenuState;
			return state;
		default:
			return state;
	}
}
export const changeMenuAC = (selector) =>
	({ type: UPDATE_SELECTOR_MENU, newMenuSelector: selector })
export const changeStateAC = (stateMenu) =>
	({ type: UPDATE_STATE_MENU, newMenuState: stateMenu })
export default headerReducer;