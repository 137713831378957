import React from "react";
import About from "./About";
import { changeVisibleFormAC } from "../../redux/about-reducer";

const AboutContainer = (props) => {
	let state = props.store.getState();
	let chenge_visible = (check_visible) => {
		let action = changeVisibleFormAC(check_visible);
		props.store.dispatch(action);
	}
	return (
		<About check_visible={state.about.check_visible} chenge_visible={chenge_visible} />
	)
}

export default AboutContainer;