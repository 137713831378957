import React from "react";
import s from "./Catalog.module.css";
import Card from "./Card/Card";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const Catalog = (props) => {
	let state = JSON.parse(localStorage.getItem("cart_list"));
	if (state === null) {
		localStorage.setItem("cart_list", JSON.stringify([]));
	}
	let bagsElement = props.catalog.bags_model.map(p =>
		<Card id={p.id}
			card_image={p.card_image}
			card_image2={p.card_image2}
			card_name={p.card_name}
			price={p.price}
			description={p.description}
			sub_description={p.sub_description}
			setSelectedModel={props.setSelectedModel}
			model={p}
			getCardStore={p.getCardStore}
			modelCount={props.catalog.model_count}
			plusModelCount={props.plusModelCount}
			setCartList={props.setCartList}
			cardId={props.cardId}
			cardIdPlus={props.cardIdPlus}
			newClassAddedProduct={props.newClassAddedProduct}
			check_open_added_product={props.check_open_added_product}
			cart_list={props.cart_list}
			catalog={props.catalog}
			newImageColor={props.newImageColor}
			change_image={props.change_image}
			link={props.catalog.card_link}
			main_link={props.link}
			price_active={p.price_active}
		/>)
	ScrollToTop();
	return (
		<div className={s.catalog_wrapper}>
		<div className={s.info_wrapper}>
			<div className={s.info_header}>
				<h1>Что такое Sample Sale?</h1>
			</div>
			<div className={s.info_text}>
				<p>Sample Sale – распродажа сэмплов, то есть образцов. Это уникальная возможность приобрести сумку по очень приятной низкой цене со скидкой 50-70%. Размер скидки зависит от состояния изделия. По запросу высылаем полный фото/видеоотчет.</p>
				<p>Также, в распродаже участвуют сумки, которые были сшиты для съемок бренда, а также модели с незначительными дефектами. </p>
				<p>Обращаем Ваше внимание, что сумки из раздела SAMPLE SALE возврату и обмену не подлежат. А также, эти модели продаются без фирменной коробки бренда.</p>
				<p>Приятных покупок!</p>
			</div>
		</div>
			<div className={s.catalog_title}>СУМКИ</div>
			<div className={s.catalog}>
				{bagsElement}
			</div>
		</div>
	)
}
export default Catalog;