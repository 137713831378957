import React from "react";
import Footer from "./Footer";
import { setLinkAC } from "../../redux/catalog-reducer";

const FooterContainer = (props) => {
    const set_link = (link) => {
		let action = setLinkAC(link);
		props.store.dispatch(action);
	}
	return (
		<Footer set_link={set_link}/>
	)
}
export default FooterContainer;