import React from "react";
import SertificateCard from "./SertificateCard";
import { setLinkAC } from "../../../redux/catalog-reducer";


const SertificateCardContainer = (props) => {
	let state = props.store.getState();
	const set_link = (link) => {
		let action = setLinkAC(link);
		props.store.dispatch(action);
	}
	return (
		<SertificateCard sertificate={state.sertificate.sertificates_new} set_link={set_link}/>
	)
}

export default SertificateCardContainer;