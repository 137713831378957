import React from "react";
import CardPage from "./CardPage";
import {
	setSelectedModelAC,
	getLocaleStorageAC,
	plusModelCountAC,
	setCartListAC,
	cardIdPlusAC,
	openAddedProductAC,
	doubleAddModelAC,
	changeColorImageAC,
	changeSliderMainImageAC,
} from "../../../redux/catalog-reducer";

const CardPageContainer = (props) => {
	let state = props.store.getState();

	let setSelectedModel = (object_model) => {
		let action = setSelectedModelAC(object_model);
		props.store.dispatch(action);
	}
	let getCardStore = () => {
		let action = getLocaleStorageAC();
		props.store.dispatch(action);
	}
	let plusModelCount = (count_card) => {
		let action = plusModelCountAC(count_card);
		props.store.dispatch(action);
	}
	let setCartList = (cart_item) => {
		let action;
		let check = true;
		if (state.catalog.model_count > 0) {
			state.catalog.cart_list.map(p => {
				if (cart_item.id === p.id) {
					let new_count = p.count;
					new_count++;
					action = doubleAddModelAC(state.catalog.cart_list.indexOf(p), new_count);
					check = false;
				} else if (check) {
					action = setCartListAC(cart_item);
					check = false;
				}
			})
		} else {
			action = setCartListAC(cart_item);
		}
		props.store.dispatch(action)
	}
	let cardIdPlus = (cartIdCount) => {
		let action = cardIdPlusAC(cartIdCount);
		props.store.dispatch(action)
	}
	let newClassAddedProduct = (new_class, new_check) => {
		let action = openAddedProductAC(new_class, new_check);
		props.store.dispatch(action)
	}
	let newImageColor = (id, new_image, new_image2) => {
		let action = changeColorImageAC(id, new_image, new_image2);
		props.store.dispatch(action);
	}

	const change_image = (new_image, index) => {
		let action = changeSliderMainImageAC(new_image, index);
		props.store.dispatch(action);
	}
	return (
		<CardPage
			bags_model={state.catalog.bags_model}
			change_image={change_image}
			slider={state.catalog.slider}

			catalog={state.catalog}
			setSelectedModel={setSelectedModel}
			getCardStore={getCardStore}
			plusModelCount={plusModelCount}
			setCartList={setCartList}
			cardId={state.catalog.cardId}
			cardIdPlus={cardIdPlus}
			newClassAddedProduct={newClassAddedProduct}
			check_open_added_product={state.catalog.check_open_added_product}
			cart_list={state.catalog.cart_list}
			newImageColor={newImageColor}
			link={state.catalog.link}
			price_active={state.catalog.price_active}
		/>
	)
}
export default CardPageContainer;