//import React, { useState } from "react";
import s from "./AboutBrand.module.css";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import image_1 from "../../image/ABOUT/1.jpg";
import image_2 from "../../image/ABOUT/2.jpg";
import image_3 from "../../image/ABOUT/3.jpg";
const AboutBrand = () => {
  /*
	const [content, setContent] = useState('Проверка');
	const click = () => {
		const type = 'Changed';
		setContent(type);
	}*/
  ScrollToTop();
  return (
    <div className={s.wrapper}>
      <div className={s.description_wrapper}>
        {/*{content}
		<br/>
		<button onClick={click}>Change</button>*/}
        <div className={s.image_wrapper}>
          <img src={image_1} alt="" />
          <img src={image_2} alt="" />
          <img src={image_3} alt="" />
        </div>
        <div className={s.text_wrapper}>
          <h1 className={s.about_brand_title}>О БРЕНДЕ</h1>
          <p>
            CALYPSO – камерный российский бренд сумок и аксессуаров ручной
            работы из натуральной кожи, созданный в Сибири для женщин. Каждый
            день наши любимые клиентки выбирают себя, уникальность, стиль,
            смелость, любовь и эстетику.
          </p>
          <p>
            Наша главная миссия - дать всем женщинам ощутить себя в центре
            внимания! С нашими аксессуарами Вы всегда будете выглядеть
            безупречно, стильно и красиво.
          </p>
          <p>
            Для производства наших изделий, мы используем натуральную кожу КРС,
            высококачественные уплотнители, итальянскую фурнитуру люксового
            качества.
          </p>
          <div className={s.citate}>
            <p>
              <i>
                «Быть не такими, как все – большая редкость в наше время! В
                мире, где тренды и стандарты могут диктовать свои правила,
                сохранить свою уникальность и самовыражение - это действительно
                ценное качество. Мне очень хотелось бы, чтобы всё больше людей
                выбирали себя, доверяли своему вкусу, были неповторимыми. А моя
                задача - помочь обрести уникальность для каждой из Вас!»
              </i>
            </p>
            <p>
              <i>Анастасия Литвинцева, основательница бренда CALYPSO</i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBrand;
