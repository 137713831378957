import React from "react";
import Sertificates from "./Sertificates";
import { setSertificateAC } from "../../redux/sertificates-reducer";
import {
	plusModelCountAC,
	setCartListAC,
	cardIdPlusAC,
	openAddedProductAC,
	doubleAddModelAC,
	setSelectedModelAC,
	setLinkAC,
} from "../../redux/catalog-reducer";
import { useEffect } from "react";

const SertificatesContainer = (props) => {
	let state = props.store.getState();
	let set_sertificate = (id, name_caption, price, image, code) => {
		let action = setSertificateAC(id, name_caption, price, image, code);
		props.store.dispatch(action);
	}
	let plusModelCount = (count_card) => {
		let action = plusModelCountAC(count_card);
		props.store.dispatch(action);
	}
	let setCartList = (cart_item) => {
		let action;
		let check = true;
		if (state.catalog.model_count > 0) {
			state.catalog.cart_list.map(p => {
				if (cart_item.id === p.id) {
					let new_count = p.count;
					new_count++;
					action = doubleAddModelAC(state.catalog.cart_list.indexOf(p), new_count);
					check = false;
				} else if (check) {
					action = setCartListAC(cart_item);
					check = false;
				}
			})
		} else {
			action = setCartListAC(cart_item);
		}
		props.store.dispatch(action)
	}
	let cardIdPlus = (cartIdCount) => {
		let action = cardIdPlusAC(cartIdCount);
		props.store.dispatch(action)
	}
	let newClassAddedProduct = (new_class, new_check) => {
		let action = openAddedProductAC(new_class, new_check);
		props.store.dispatch(action)
	}
	let setSelectedModel = (object_model) => {
		let action = setSelectedModelAC(object_model);
		props.store.dispatch(action);
	}
	const set_link = () => {
		let action = setLinkAC("/sertificates");
		props.store.dispatch(action);
	}
	useEffect(() => {
		set_link();
	}, []);
	return (
		<Sertificates
			catalog={state.catalog}
			sertificate={state.sertificate}
			catalog_link={state.catalog.card_link}
			set_sertificate={set_sertificate}

			plusModelCount={plusModelCount}
			setCartList={setCartList}
			cardIdPlus={cardIdPlus}
			newClassAddedProduct={newClassAddedProduct}
			setSelectedModel={setSelectedModel}
			check_open_added_product={state.catalog.check_open_added_product}
		/>
	)
}

export default SertificatesContainer;