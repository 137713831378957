import React from "react";
import s from "./CartPage.module.css";
import CartList from './CartList/CartList'


const CardPage = (props) => {
	let state = JSON.parse(localStorage.getItem("cart_list"));
	let cartList = state.map(p =>
		<CartList id={p.id}
			card_image={p.card_image}
			card_image2={p.card_image2}
			card_name={p.card_name}
			price={p.price}
			description={p.description}
			sub_description={p.sub_description}
			count={p.count}
			index={state.indexOf(p)}
			setCountModel={props.setCountModel}
			delCardOfCart={props.delCardOfCart}
			plusModelCount={props.plusModelCount}
			summResulCart={props.summResulCart}
			summ={props.summ} />
	)
	let variable_summ = 0;
	state.map(p =>
		variable_summ += Number(p.count * p.price)
	)
	
	return (
		<div className={s.cart_wrapper}>
			<div className={s.cart_title}>Корзина</div>
			<div className={s.cart_body}>
				<div className={s.body_products}>
					{cartList}
				</div>
				<div className={s.body_result}>
					<span className={s.result_title}>Ваш заказ</span>
					<div className={s.result_summ}>
						<span>Итого к оплате: </span>
						<span className={s.result_price}>{variable_summ}</span>
					</div>
					<button className={s.checkout_btn}>ОФОРМИТЬ ЗАКАЗ</button>
				</div>
			</div>
		</div>
	)
}
export default CardPage;