import React from "react";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import s from './Categories.module.css'
import Category from "./Сategory/Category";

const Categories = (props) => {
    let categoriesElements = props.categories.map(p=>
        <Category id={p.id} categories_title={p.categories_title} background_image={p.background_image} button_link={p.button_link}/>
        )
    return(
        <div className={s.category_list}>
            {categoriesElements}
        </div>
    )
}
export default Categories;