import React from "react";
import SliderContainer from "./Slider/SliderContainer";
import CategoriesContainer from "./Categories/CategoriesContainer";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import s from "../Main/Main.module.css"
import image_1 from "../../image/MAIN/1.webp"
import image_2 from "../../image/MAIN/2.webp"

const Main = (props) => {
    ScrollToTop();
    return(
        <div>
            <SliderContainer store={props.store}/>
            <div className={s.image_wrapper}>
               <img src={image_1} alt="" /> 
               <img src={image_2} alt="" /> 
            </div>
            <CategoriesContainer store={props.store}/>
        </div>
    )
}
export default Main;