import React from "react";
import s from "./Sertificates.module.css";
import Card from "../Catalog/Card/Card";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const Sertificates = (props) => {
  let sertificatsElement = props.sertificate.sertificates_new.map((p) => (
    <Card
      id={p.id}
      card_image={p.card_image}
      card_image2={p.card_image}
      card_name={p.card_name}
      price={p.price}
      main_link={props.sertificate.link}
      link={props.catalog_link}
      set_sertificate={props.set_sertificate}
      code={p.codes}
      model={p}
      plusModelCount={props.plusModelCount}
      setCartList={props.setCartList}
      cardIdPlus={props.cardIdPlus}
      newClassAddedProduct={props.newClassAddedProduct}
      setSelectedModel={props.setSelectedModel}
      check_open_added_product={props.check_open_added_product}
      modelCount={props.catalog.model_count}
      price_active={p.price_active}
    />
  ));
  ScrollToTop();
  return (
    <div className={s.catalog_wrapper}>
      <div className={s.info_wrapper}>
        <div className={s.info_header}>
          <h1>Подарочный сертификат</h1>
        </div>
        <div className={s.info_text}>
          <p>
            Подарите возможность выбрать понравившуюся сумку своим родным и
            близким. Приобретайте наши электронные подарочные сертификаты и
            дарите, даже если ваш близкий человек находится за много километров
            от вас.{" "}
          </p>
        </div>
      </div>
      <div className={s.catalog_title}>СЕРТИФИКАТЫ</div>
      <div className={s.catalog}>{sertificatsElement}</div>
    </div>
  );
};

export default Sertificates;
