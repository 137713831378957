import React from "react";
import s from "./ColorSecond.module.css"

const ColorSecond = (props) => {
    let style;
    if(props.new_index === props.index){
        style={
            border: "3px solid gold",
            width: "94px",
            height: "94px",
        }
    }
    let changeColorDetail = () => {
        props.changeColorDetail(props.index)
    }
    return (
        <button className={s.model_wrapper} onClick={changeColorDetail}>
            <div>{props.name}</div>
            <img className={s.image_model} src={props.image} style={style} alt="" />
        </button>
    )
}

export default ColorSecond;