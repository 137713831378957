import React from 'react';
import s from "./CartList.module.css"

const CardList = (props) => {
	let state = props.count;
	let summ = props.summ;
	let count_item = JSON.parse(localStorage.getItem("cardCount"));
	let plusCount = () => {
		state++;
		count_item++;
		summ += (Number(price));
		props.summResulCart(summ)
		countHeader();
		props.setCountModel(state, props.index);
	}
	let minusCount = () => {
		if (state > 1) {
			state--;
			count_item--;
			summ -= (Number(price));
			props.summResulCart(summ)
			countHeader();
			props.setCountModel(state, props.index);
		} else {
			delCard();
		}
	}
	let countHeader = () => {
		if (!count_item) count_item = '';
		props.plusModelCount(count_item);
	}
	let delCard = () => {
		count_item -= state;
		summ -= (Number(price) * Number(state));
		props.summResulCart(summ)
		countHeader();
		props.delCardOfCart(props.index);
	}
	let price = props.price;

	return (
		<div className={s.card}>
			<img src={props.card_image} className={s.card_image} alt="" />
			<div className={s.card_description}>
				<div className={s.card_name}>{props.card_name}</div>
				<div>{props.price}</div>
			</div>
			<div className={s.button_block}>
				{/*<button className={s.card_button} onClick={minusCount}>-</button>*/}
				<span>{state}</span>
				{/*<button className={s.card_button} onClick={plusCount}>+</button>*/}
			</div>
			<div className={s.price_block}>
				<span>{Number(price) * Number(state)}</span>
			</div>
			<button className={s.delete_btn} onClick={delCard}>✕</button>
		</div>
	)
}
export default CardList;