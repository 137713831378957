import React from "react";
import s from "./About.module.css"
import ScrollToTop from "../ScrollToTop/ScrollToTop";
const About = (props) => {
	let style;
	if (props.check_visible === 1) {
		style = {
			display: "block",
		}
	} else if (props.check_visible === -1) {
		style = {
			display: "none",
		}
	}
	let chenge_visible = () => {
		let chenge_state = props.check_visible;
		chenge_state *= -1;
		props.chenge_visible(chenge_state);
	}
	ScrollToTop();
	return (
		<div className={s.wrapper}>
			<nav className={s.menu}>
				<ul className={s.list_menu}>
					<a className={s.item_link} href="#delivery">
						<li className={s.menu_item}>
							ДОСТАВКА И ОПЛАТА
						</li>
					</a>
					<a className={s.item_link} href="#refund">
						<li className={s.menu_item}>
							ГАРАНТИЯ И ВОЗВРАТ
						</li>
					</a>
					<a className={s.item_link} href="#contacts">
						<li className={s.menu_item}>
							КОНТАКТЫ
						</li>
					</a>
					{/*
					<a className={s.item_link} href="#reviews">
						<li className={s.menu_item}>
							ОТЗЫВЫ
						</li>
					</a>
					*/}
				</ul>
			</nav>
			<div className={s.content_wrapper}>
				<div className={s.content_part} id='delivery'>
					<h1 className={s.header_main}>ДОСТАВКА И ОПЛАТА</h1>
					<h2 className={s.header}>ДОСТАВКА</h2>
					<p className={s.content_text}>Мы отправляем наши изделия по всей России транспортной компанией CDEK. Отправка осуществляется после 100% оплаты товара.</p>
					<p className={s.content_text}>Действует бесплатная доставка по городу Иркутск и селу Хомутово. Доставка в другие города оплачивается по тарифу ТК. Стоимость доставки рассчитывается и индивидуально при оформлении заказа.</p>
					<p className={s.content_text}>Каждое изделие упаковывается в пыльник и фирменную коробку бренда (кроме товаров из раздела "SAMPLE SALE"), а также запечатывается в упаковку транспортной компании.</p>
					<h2 className={s.header}>ОПЛАТА</h2>
					<p className={s.content_text}>Оплата на нашем сайте осуществляется переводом по номеру телефона/реквизитам счета через удобный вам банк.</p>
					<p className={s.content_text}>Услуга «Пошив на заказ» осуществляется по предоплате 50% от суммы заказа. Остальные 50 % оплачиваете по готовности.</p>
					<h2 className={s.header}>СРОК ПОШИВА</h2>
					<p className={s.content_text}>Срок пошива – до 14 дней, иногда срок может быть увеличен из-за загруженности, но мы всегда об этом предупреждаем!</p>
				</div>
				<div className={s.space}></div>
				<div className={s.content_part} id='refund'>
					<h1 className={s.header_main}>ГАРАНТИЯ И ВОЗВРАТ</h1>
					<h2 className={s.header}>ГАРАНТИЯ</h2>
					<p className={s.content_text}>На все наши изделия мы предоставляем гарантию 2 месяца со дня покупки. </p>
					<p className={s.content_text}><strong>ВАЖНО!</strong> Гарантийный срок на сумки не действует в отношении дефектов, которые дали о себе знать вследствие нарушения условий эксплуатации. То есть, если на изделии имеются механические повреждения, следы неправильного хранения, небрежного ухода, несоблюдения норм нагрузки, обмену и возврату оно не подлежит.</p>
					<p className={s.content_text}>Что не является браком?</p>
					<ul>
						<li>незначительные потертости на фурнитуре;</li>
						<li>неоднородность кожи. Потертости и неоднородности натуральной кожи — это не дефект, а естественный рисунок, который подтверждает ее натуральность;</li>
						<li>утеря элементов фурнитуры, закрепленных на винтах;</li>
						<li>отличие оттенка кожи от цвета, представленного на сайте. Из-за разного освещения, цвет изделия может незначительно отличаться.</li>
					</ul>
					<h2 className={s.header}>ВОЗВРАТ</h2>
					<p className={s.content_text}>Возврат товара возможен в течение 14 дней со дня его получения. </p>
					<p className={s.content_text}><strong>ВАЖНО!</strong> У товара не должно быть следов носки. Товар обязательно возвращается в полном комплекте (сумка, ремень, пыльник, коробка)</p>
					<p className={s.content_text}>Отправка товара осуществляется за счет покупателя любой ТК по желанию.</p>
					<p className={s.content_text}>Возврат денежных средств будет произведен в течение 10 дней с момента получения нами посылки. В пределах указанного срока мы вправе провести экспертизу товара с целью определить его качество и сохранность потребительских свойств. </p>
					<p className={s.content_text}><i><b>Просим Вас аккуратно и бережно относится к нашим изделиям!</b></i></p>
				</div>
				<div className={s.space}></div>
				<div className={s.content_part} id='contacts'>
					<h1 className={s.header_main}>КОНТАКТЫ</h1>
					<p className={s.content_text}>Наш бренд находится в постоянном развитии! Мы всегда открыты и рады сотрудничеству!</p>
					<p className={s.content_text}>По вопросам, связанным с рекламой, съемками, идеями для коллабораций обращайтесь на почту <a className={s.click_link} href="mailto: info@calypsobrand.ru">info@calypsobrand.ru</a> или Telegram/WA <a className={s.click_link} href="tel: +79247039139">89247039139</a></p>
					<p className={s.content_text}>По вопросам мелкого опта пишите на info@calypsobrand.ru</p>
					<p className={s.content_text}>Присылайте ваши вопросы в месседжеры, указанные ниже. Мы всегда рады Вас проконсультировать по товарам, палитре цветов, оплате и др.</p>
					<p className={s.content_text}>Телефон: <a className={s.click_link} href="tel: +79247039139">+79247039139</a> Telegram/ WA</p>
					<p className={s.content_text}>Email: <a className={s.click_link} href="mailto: info@calypsobrand.ru">info@calypsobrand.ru</a></p>
				</div>
				<div className={s.space}></div>
				{/*
				<div className={s.content_part} id='reviews'>
					<h1 className={s.header_main}>ОТЗЫВЫ</h1>
					<p className={s.content_text}>Уважаемые покупатели!</p>
					<p className={s.content_text}>Будем очень рады, если Вы оставите нам отзыв!</p>
					<p className={s.content_text}>Каждый день мы работаем над повышением качества наших сумок. Помогите сделать наши изделия еще лучше!</p>
					<p className={s.content_text}>Спасибо, что выбрали CALYPSO!</p>
					<button onClick={chenge_visible} className={s.reviews_button}>ОСТАВИТЬ ОТЗЫВ</button>
					<form className={s.form_body} action="" style={style}>
						<button type="button" className={s.form_close_button} onClick={chenge_visible}>
							<svg
								width="30px"
								height="30px"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
									fill="#2e2e2e"
								/>
							</svg>
						</button>
						<div>
							<h2 className={s.form_header}>ОСТАВИТЬ ОТЗЫВ</h2>
							<label htmlFor="">
								Ваше имя: <input type="text" />
							</label>
							<label htmlFor="">
								Номер телефона: <input type="text" />
							</label>
							<label htmlFor="">
								e-mail: <input type="text" />
							</label>
							<label htmlFor="">
								Текст отзыва: <textarea type="text" rows="7" />
							</label>
						</div>
						<label className={s.checkbox} htmlFor="">
							<input type="checkbox" /> <span>Соглашаюсь с политикой обработки персональных данных</span>
						</label>
						<input className={s.form_button} type="button" value="ОТПРАВИТЬ" />
					</form>
					<div className={s.back} style={style}></div>
				</div>
				*/}
			</div>
		</div>
	)
}

export default About;