import React from "react";
import s from "./Furniture.module.css"

const Furniture = (props) => {
    let style;
    if(props.new_index === props.index){
        style={
            border: "3px solid gold",
            width: "94px",
            height: "94px",
        }
    }
    let changeFurniture = () => {
        props.changeFurniture(props.index, props.name)
    }
    return (
        <button className={s.model_wrapper} onClick={changeFurniture}>
            <div className={s.name}>{props.name}</div>
            <img className={s.image_model} src={props.image} style={style} alt="" />
        </button>
    )
}

export default Furniture;