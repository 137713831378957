import React from "react";
import s from "./Size.module.css"

const Size = (props) => {
	let style;
	if (props.new_index === props.index) {
		style = {
            border: "3px solid gold",
            width: "94px",
            height: "94px",
		}
	}
	let changeSize = () => {
		props.changeSize(
			props.index,
			props.name,);
	}
	return (
		<button className={s.model_wrapper} onClick={changeSize}>
			<div className={s.name} style={style}>{props.name}</div>
		</button>
	)
}

export default Size;