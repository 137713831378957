let CHENGE_VISIBLE_FORM = 'CHENGE-VISIBLE-FORM';

let initialState = {
	check_visible: -1,
}
const aboutReducer = (state = initialState, action) => {

	switch (action.type) {
		case CHENGE_VISIBLE_FORM:
			state.check_visible = action.new_check_visible;
		default:
			return state;
	}
}

export const changeVisibleFormAC = (check_visible) => ({
	type: CHENGE_VISIBLE_FORM, new_check_visible: check_visible
})

export default aboutReducer;