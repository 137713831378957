import React from "react";
import s from "./SliderBar.module.css"

const SliderBar = (props) => {
	let style;
	if (props.index_checked === props.index) {
		style = {
			border: "2px solid #000",
		}
	} else {
		style = {
			border: "0",
		}
	}

	let change_new_image = () => {
		props.change_image(props.image, props.index)
	}
	return (
		<div>
			<button onClick={change_new_image}>
				<img className={s.image} style={style} src={props.image} alt="" />
			</button>
		</div>
	)
}

export default SliderBar;