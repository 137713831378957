import React from "react";
import Header from "./Header";
import { changeMenuAC, changeStateAC } from "../../redux/header-reducer";
import { resultSummFuncAC, openAddedProductAC, setLinkAC } from "../../redux/catalog-reducer";

const HeaderContainer = (props) => {
	let state = props.store.getState();
	let changeSelectorMenu = (selector) => {
		let action = changeMenuAC(selector)
		props.store.dispatch(action);
	}
	let changeStateMenu = (stateMenu) => {
		let action = changeStateAC(stateMenu)
		props.store.dispatch(action);
	}
	let getSummResult = (summ) => {
		let action = resultSummFuncAC(summ);
		props.store.dispatch(action);
	}
	let newClassAddedProduct = (new_class, new_check) => {
		let action = openAddedProductAC(new_class, new_check);
		props.store.dispatch(action)
	}
	const set_link = (link) => {
		let action = setLinkAC(link);
		props.store.dispatch(action);
	}
	return (
		<Header
			menu_selector={state.header.menu_selector}
			menu_state={state.header.menu_state}
			changeSelectorMenu={changeSelectorMenu}
			changeStateMenu={changeStateMenu}
			model_count={state.catalog.model_count}
			getSummResult={getSummResult}
			cart_list={state.catalog.cart_list}
			cart_buy={state.catalog.selected_model}
			added_product={state.catalog.added_product}
			newClassAddedProduct={newClassAddedProduct}
			set_link={set_link}
		/>
	)
}
export default HeaderContainer;