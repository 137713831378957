import { combineReducers, legacy_createStore as createStore } from "redux";
import headerReducer from "./header-reducer";
import sliderReducer from "./slider-reducer";
import catalogReducer from "./catalog-reducer";
import castomReducer from "./castom-reducer";
import sertificatesReducer from "./sertificates-reducer";
import aboutReducer from "./about-reducer";

let reducers = combineReducers({
	header: headerReducer,
	slider: sliderReducer,
	catalog: catalogReducer,
	castom: castomReducer,
	sertificate: sertificatesReducer,
	about: aboutReducer,
})

let store = createStore(reducers);
export default store;