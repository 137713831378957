import React from "react";
import s from "./CardPage.module.css";
import CardDescription from "./CardDescription/CardDescription";
import { NavLink, useParams } from "react-router-dom";
import CardSlider from "./CardSlider/CardSlider";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

const CardPage = (props) => {
	const { id } = useParams();
	let state = props.bags_model.find(p => p.id == id);
	let model = `${state.card_name}`
	let color = `${state.card_color.color_name}`
	let link = "https://wa.me/79247039139?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21%20%D0%97%D0%B0%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D0%BE%D0%B2%D0%B0%D0%BB%D0%B0%20%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D1%8C%20-%20" + "%20" + model + "%20%D0%B2%20%D1%86%D0%B2%D0%B5%D1%82%D0%B5%20" + color + ".%20%D0%9A%D0%B0%D0%BA%20%D0%BE%D1%84%D0%BE%D1%80%D0%BC%D0%B8%D1%82%D1%8C%20%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%3F";
	let style_price_line;
	let style_price_active;
	if (state.price_active === "") {
		style_price_line = {
			textDecoration: "none",
			margin: 0
		}
		style_price_active = {
			display: "none"
		}
	}

	let style = {
		background: state.card_color.color_code,
	}

	let setModelCart = () => {
		change_new_image();
		props.setSelectedModel(state);
	}
	let plusCount = () => {
		let count = props.modelCount;
		count++;
		props.plusModelCount(count);
	}

	let setCart = () => {
		props.setCartList(state)
		setModelCart();
		Open_buy_card()
		plusCount();
	}
	let Open_buy_card = () => {
		if (props.check_open_added_product) {
			props.newClassAddedProduct('Header_card_info__1wL8g', false);
			setTimeout(Close_buy_card, 5000);
		};
	}
	let Close_buy_card = () => {
		props.newClassAddedProduct('Header_card_info_close__Kt69q', true)
	}
	let change_new_image = () => {
		props.change_image(props.slider.image, 0)
	}
	ScrollToTop();
	return (
		<div className={s.card_wrapper}>
			<NavLink className={s.button_back} to="/catalog">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M4 12H20M4 12L8 8M4 12L8 16" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
				<span>В КАТАЛОГ</span>
			</NavLink>
			<div className={s.card}>
				<CardSlider change_image={props.change_image}
					slider_image={state.slider_image}
					index={props.slider.index}
					main_image={props.slider.image}
				/>
				<div className={s.description_block}>
					<div className={s.card_title}>МОДЕЛЬ - {state.card_name}</div>
					<div className={s.card_price_wrapper}>
						<span style={style_price_active}>{state.price_active}₽</span>
						<span className={s.card_price} style={style_price_line}>{state.price}₽</span>
					</div>
					<div className={s.color_wrapper}>
						<div className={s.color_text}>Цвет - {state.card_color.color_name}</div>
						<div className={s.color_circle} style={style}></div>
					</div>
					{/*<button className={s.offer_button} onClick={(setCart)}>ОТПРАВИТЬ ЗАПРОС</button>*/}
					<a className={s.offer_button} href={link} target="_blank">ОТПРАВИТЬ ЗАПРОС</a>
					{/*<button className={s.offer_button} onClick={(setCart)}>В КОРЗИНУ</button>*/}
					<div className={s.card_description}>
						<CardDescription
							accessories={state.description.accessories}
							lining={state.description.lining}
							material={state.description.material}
							size={state.description.size}
							text={state.description.text}
							sub_description={state.description.sub_description}
							weight={state.description.weight} />
					</div>
				</div>
			</div>
		</div>
	)
}
export default CardPage;

export const cardInfoLoader = async ({ params }) => {
	const { id } = params;

	const res = await fetch("http://localhost:3000/catalog" + id);
	return res.json()
}