import React from "react";
import s from './SertificateCard.module.css'
import { useParams, NavLink } from "react-router-dom";
import SertificateDescriptionList from "./SertificateDescriptionList/SertificateDescriptionList";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

const SertificateCard = (props) => {
	const { id } = useParams();
	let state = props.sertificate.find(p => p.id == id);
	let link = "https://wa.me/79247039139?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!%20%D0%A5%D0%BE%D1%87%D1%83%20%D0%BF%D1%80%D0%B8%D0%BE%D0%B1%D1%80%D0%B5%D1%81%D1%82%D0%B8%20%D1%81%D0%B5%D1%80%D1%82%D0%B8%D1%84%D0%B8%D0%BA%D0%B0%D1%82%20%D0%BD%D0%BE%D0%BC%D0%B8%D0%BD%D0%B0%D0%BB%D0%BE%D0%BC%20%20" + state.price + ".%20%D0%9A%D0%B0%D0%BA%20%D0%BE%D1%84%D0%BE%D1%80%D0%BC%D0%B8%D1%82%D1%8C%20%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%3F"
	let set_link_sertificate = () => {
		props.set_link("/sertificates");
	}
	let description_list = state.description.map(p =>
		<SertificateDescriptionList description={p} />
	)
	ScrollToTop();
	return (
		<div className={s.card_wrapper}>
			<NavLink to="/sertificates">
				<button className={s.button_back} onClick={set_link_sertificate}>
					<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4 12H20M4 12L8 8M4 12L8 16" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					<span>К СЕРТИФИКАТАМ</span>
				</button>
			</NavLink>
			<div className={s.card}>
				<div className={s.description_block}>
					<img className={s.image_main} src={state.card_image} alt="" />
					<div className={s.right_block}>
						<div className={s.card_title}>{state.card_name}</div>
						<div className={s.card_price}>{state.price} ₽</div>
						{/*
						<button className={s.offer_button}>В КОРЗИНУ</button>
						*/}
						<a className={s.offer_button} href={link} target="_blank">ОТПРАВИТЬ ЗАПРОС</a>
						<div className={s.card_description}>{description_list}</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SertificateCard;

export const cardInfoLoader = async ({ params }) => {
	const { id } = params;

	const res = await fetch("http://localhost:3000/sertificates" + id);
	return res.json()
}