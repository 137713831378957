import React from "react";
import { NavLink } from "react-router-dom";
import tg_icon from "../../image/icon-telegram.svg";
import vk_icon from "../../image/icon-vk.svg";
import pinterest_icon from "../../image/icon-pinterest.svg";
import instagram_icon from "../../image/icon-instagram.svg";
import mail_icon from "../../image/icon-mail.png";
import phone_icon from "../../image/icon-phone.png";
import doc_politika_konf from "../../documents/Политика конфеденциальности.pdf";
import doc_oferta from "../../documents/Договор оферты.pdf"

import s from "./Footer.module.css";

const Footer = (props) => {
  let set_link = (link) => {
    props.set_link(link);
  };
  let set_link_catalog = () => {
    set_link("/catalog");
  };
  let set_link_sertificate = () => {
    set_link("/sertificates");
  };
  return (
    <div className={s.wrapper}>
      <div className={s.footer_wrapper}>
        <NavLink to="/" className={s.logo}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="black"
              id="logo"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="12"
              height="12"
            >
              <path d="M17.5.917a6.4,6.4,0,0,0-5.5,3.3A6.4,6.4,0,0,0,6.5.917,6.8,6.8,0,0,0,0,7.967c0,6.775,10.956,14.6,11.422,14.932l.578.409.578-.409C13.044,22.569,24,14.742,24,7.967A6.8,6.8,0,0,0,17.5.917Z" />
            </svg>
          </span>
          <span>
            <div className={s.logo_text_main}>C A L Y P S O</div>
          </span>
        </NavLink>
        <div className={s.menu_wrapper}>
          <h3 className={s.footer_header}>МЕНЮ</h3>
          <nav className={s.menu_wrapper}>
            <ul>
              <li>
                <NavLink to="/">
                  <span>ГЛАВНАЯ</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/catalog" onClick={set_link_catalog}>
                  <span>КАТАЛОГ</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/castom">
                  <span>ПОШИВ НА ЗАКАЗ</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/sertificates" onClick={set_link_sertificate}>
                  <span>ПОДАРОЧНЫЙ СЕРТИФИКАТ</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/buyer">
                  <span>ПОКУПАТЕЛЯМ</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/about">
                  <span>О БРЕНДЕ</span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className={s.about_wrapper}>
          <h3 className={s.footer_header}>КОНТАКТЫ</h3>
          <div className={s.contacts_wrapper}>
            <a href="tel:+79247039139">
              <div className={s.contacts_item}>
                <span className={s.icon_wrapper}>
                  <img src={phone_icon} alt="" />
                </span>
                <span>+7 (924)-703-91-39</span>
              </div>
            </a>
            <a href="mailto:info@calypsobrand.ru">
              <div className={s.contacts_item}>
                <span className={s.icon_wrapper}>
                  <img src={mail_icon} alt="" />
                </span>
                <span>info@calypsobrand.ru</span>
              </div>
            </a>
            <a href="https://instagram.com/calypso.brand">
              <div className={s.contacts_item}>
                <span className={s.icon_wrapper}>
                  <img src={instagram_icon} alt="" />
                </span>
                <span>instagram.com/calypso.brand</span>
              </div>
            </a>
            <a href="https://t.me/calypsobrand">
              <div className={s.contacts_item}>
                <span className={s.icon_wrapper}>
                  <img src={tg_icon} alt="" />
                </span>
                <span>t.me/calypsobrand</span>
              </div>
            </a>
            <a href="https://vk.com/calypsoirk">
              <div className={s.contacts_item}>
                <span className={s.icon_wrapper}>
                  <img src={vk_icon} alt="" />
                </span>
                <span>vk.com/calypsoirk</span>
              </div>
            </a>
            <a href="https://pin.it/1vGz8GbPR">
              <div className={s.contacts_item}>
                <span className={s.icon_wrapper}>
                  <img src={pinterest_icon} alt="" />
                </span>
                <span>pin.it/1vGz8GbPR</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className={s.copyrating}>
        <span>Ⓒ Calypsobrand, 2024</span>
        <a href={doc_politika_konf} target="_blank">Политика конфеденциальности</a>
        <a href={doc_oferta} target="_blank">Договор оферты</a>
      </div>
    </div>
  );
};
export default Footer;
