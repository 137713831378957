import bag1 from "../image/SARAH/card_1.webp"
import bag12 from "../image/SARAH/card_2.webp"
import bag13 from "../image/SARAH/card_3.webp"
import bag14 from "../image/SARAH/card_4.webp"
import bag15 from "../image/SARAH/card_5.webp"
import bag16 from "../image/SARAH/card_6.webp"

import bag2 from "../image/OLIVIA/Blue/bag2.webp"
import bag22 from "../image/OLIVIA/Blue/bag22.webp"
import bag23 from "../image/OLIVIA/Blue/bag23.webp"
import bag24 from "../image/OLIVIA/Blue/bag24.webp"
import bag25 from "../image/OLIVIA/Blue/bag25.webp"
import bag26 from "../image/OLIVIA/Blue/bag26.webp"

import bag3 from "../image/OLIVIA/Chocolate/bag3.webp"
import bag32 from "../image/OLIVIA/Chocolate/bag32.webp"
import bag33 from "../image/OLIVIA/Chocolate/bag33.webp"
import bag34 from "../image/OLIVIA/Chocolate/bag34.webp"
import bag35 from "../image/OLIVIA/Chocolate/bag35.webp"
import bag36 from "../image/OLIVIA/Chocolate/bag36.webp"
/*
import bag4 from "../image/BATTY_М/bag4.webp"
import bag42 from "../image/BATTY_М/bag42.webp"
import bag43 from "../image/BATTY_М/bag43.webp"
import bag44 from "../image/BATTY_М/bag44.webp"
import bag45 from "../image/BATTY_М/bag45.webp"
import bag46 from "../image/BATTY_М/bag46.webp"
*/
import bag5 from "../image/BATTY_S/bag5.webp"
import bag52 from "../image/BATTY_S/bag52.webp"
import bag53 from "../image/BATTY_S/bag53.webp"
import bag54 from "../image/BATTY_S/bag54.webp"
import bag55 from "../image/BATTY_S/bag55.webp"
import bag56 from "../image/BATTY_S/bag56.webp"

import bag6 from "../image/ROSE/bag6.webp"
import bag62 from "../image/ROSE/bag62.webp"
import bag63 from "../image/ROSE/bag63.webp"
import bag64 from "../image/ROSE/bag64.webp"
import bag65 from "../image/ROSE/bag65.webp"
import bag66 from "../image/ROSE/bag66.webp"

import bag7 from "../image/MIA/bag72.png"
import bag72 from "../image/MIA/bag7.png"
import bag73 from "../image/MIA/bag73.png"
import bag74 from "../image/MIA/bag74.png"
import bag75 from "../image/MIA/bag75.png"
import bag76 from "../image/MIA/bag76.png"

const SET_MODEL = 'SET-MODEL';
const SET_LOCALE_STORAGE = 'SET-LOCALE-STORAGE';
const SET_CART_LIST = 'SET-CART-LIST';
const GET_LOCALE_STORAGE = 'GET-LOCALE-STORAGE';
const PLUS_MODEL_COUNT = 'PLUS-MODEL-COUNT';
const PLUS_CART_MODEL = 'PLUS-CART-MODEL';
const CARD_ID_PLUS = 'CARD_ID_PLUS';
const DELL_CART_ITEM = 'DELL-CART-ITEM';
const RESULT_SUMM_FUNC = 'RESULT-SUMM-FUNC';
const OPEN_ADDED_PRODUCT = 'OPEN-ADDED-PRODUCT';
const DOUBLE_ADD_MODEL = 'DOUBLE-ADD-MODEL';
const CHANGE_COLOR_IMAGE = 'CHANGE-COLOR-IMAGE';
const CHANGE_SLIDER_MAIN_IMAGE = 'CHANGE-SLIDER-MAIN-IMAGE'
const SET_LINK = 'SET-LINK';

let initialState = {
	categories: [
		{
			id: 1,
			categories_title: "SAMPLE SALE",
			background_image: bag1,
			button_link: "/catalog",
		},
		{
			id: 2,
			categories_title: "ПОШИВ НА ЗАКАЗ",
			background_image: bag2,
			button_link: "/castom",
		},
		{
			id: 3,
			categories_title: "ПОДАРОЧНЫЕ СЕРТИФИКАТЫ",
			background_image: bag5,
			button_link: "/sertificates",
		},
	],
	bags_model: [
		{
			id: 0,
			card_image: bag1,
			card_image2: bag12,
			card_name: "SARAH",
			price: "18400",
			price_active: "8000",
			card_color: {
				color_name: "Barbie",
				color_code: "#ff006a"
			},
			count: 1,
			description: {
				size: 'Размеры 28*17*8 см',
				material: 'Натуральная кожа',
				accessories: 'Итальянская фурнитура цвета золото',
				lining: 'Кожаный подклад. Внутри одно основное отделение и карман на молнии',
				text: 'Способ открывания – клапан на потайных магнитах',
				sub_description: "В комплекте плечевой регулируемый ремень длиной 120 см, пыльник",
				weight: "Вес ~ 690 гр."
			},
			slider_image: [
				{ image: bag1 },
				{ image: bag12 },
				{ image: bag13 },
				{ image: bag14 },
				{ image: bag15 },
				{ image: bag16 },
			],
		},
		{
			id: 1,
			card_image: bag2,
			card_image2: bag22,
			card_name: "OLIVIA",
			price: "18800",
			price_active: "8000",
			card_color: {
				color_name: "Blue",
				color_code: "#49637c"
			},
			count: 1,
			description: {
				size: 'Размеры 22*18*10 см',
				material: 'Натуральная кожа',
				accessories: 'Итальянская фурнитура цвета золото',
				lining: 'Текстильный подклад',
				text: 'Внутри одно основное отделение и карман на молнии, способ открывания – молния',
				sub_description: "В комплекте плечевой регулируемый ремень длиной 120 см, пыльник",
				weight: "Вес ~ 550 гр."
			},
			slider_image: [
				{ image: bag2 },
				{ image: bag22 },
				{ image: bag23 },
				{ image: bag24 },
				{ image: bag25 },
				{ image: bag26 },
			],
		},
		{
			id: 2,
			card_image: bag3,
			card_image2: bag32,
			card_name: "OLIVIA",
			price: "18800",
			price_active: "8000",
			card_color: {
				color_name: "Chocolate",
				color_code: "#4e4242"
			},
			count: 1,
			description: {
				size: 'Размеры 22*18*10 см',
				material: 'Натуральная кожа',
				accessories: 'Итальянская фурнитура цвета серебро',
				lining: 'Текстильный подклад',
				text: 'Внутри одно основное отделение и карман на молнии, способ открывания – молния',
				sub_description: "В комплекте плечевой регулируемый ремень длиной 120 см, пыльник",
				weight: "Вес ~ 550 гр."
			},
			slider_image: [
				{ image: bag3 },
				{ image: bag32 },
				{ image: bag33 },
				{ image: bag34 },
				{ image: bag35 },
				{ image: bag36 },
			],
		},/*
		{
			id: 3,
			card_image: bag4,
			card_image2: bag42,
			card_name: "BATTY M",
			price: "18900",
			card_color: {
				color_name: "Taupe",
				color_code: "#83766c"
			},
			count: 1,
			description: {
				size: 'Размеры 22*18*10 см',
				material: 'Натуральная кожа',
				accessories: 'Итальянская фурнитура цвета золото',
				lining: 'Текстильный подклад',
				text: 'Внутри одно основное отделение и карман на молнии, способ открывания – молния',
				sub_description: "В комплекте плечевой регулируемый ремень длиной 120 см, пыльник, пошив в любом цвете из Палитры "
			},
			slider_image: [
				{ image: bag4 },
				{ image: bag42 },
				{ image: bag43 },
				{ image: bag44 },
				{ image: bag45 },
				{ image: bag46 },
			],
		},*/
		{
			id: 3,
			card_image: bag5,
			card_image2: bag52,
			card_name: "BATTY size S",
			price: "17900",
			price_active: "7000",
			card_color: {
				color_name: "Black",
				color_code: "#000"
			},
			count: 1,
			description: {
				size: 'Размеры 24*12*12см',
				material: 'Натуральная кожа',
				accessories: 'Итальянская фурнитура цвета золото',
				lining: 'Текстильный подклад',
				text: 'Внутри одно основное отделение и карман на молнии, способ открывания – молния',
				sub_description: "В комплекте плечевой регулируемый ремень длиной 120 см, пыльник",
				weight: "Вес ~ 400 гр."
			},
			slider_image: [
				{ image: bag5 },
				{ image: bag52 },
				{ image: bag53 },
				{ image: bag54 },
				{ image: bag55 },
				{ image: bag56 },
			],
		},
		/*{
			id: 4,
			card_image: bag6,
			card_image2: bag62,
			card_name: "ROSE",
			price: "21000",
			price_active: "",
			card_color: {
				color_name: "Taupe",
				color_code: "#b9a281"
			},
			count: 1,
			description: {
				size: 'Размеры 31,5*27,5*15 см',
				material: 'Натуральная кожа',
				accessories: 'Итальянская фурнитура цвета серебро',
				lining: 'Подклад - фибра',
				text: 'Внутри одно основное отделение и накладной карман, способ открывания – молния',
				sub_description: "В комплекте пыльник",
				weight: "Вес ~ 500 гр."
			},
			slider_image: [
				{ image: bag6 },
				{ image: bag62 },
				{ image: bag63 },
				{ image: bag64 },
				{ image: bag65 },
				{ image: bag66 },
			],
		},*/
		{
			id: 5,
			card_image: bag7,
			card_image2: bag72,
			card_name: "MIA",
			price: "6500",
			price_active: "3250",
			card_color: {
				color_name: "Gray",
				color_code: "#848580"
			},
			count: 1,
			description: {
				size: 'Размеры 24*12см',
				material: 'Натуральная кожа',
				accessories: 'Cпособ открывания – кулиска. Длина ремня регулируется',
				lining: 'Подклад - нетканный материал фибра',
				text: 'Внутри одно основное отделение и накладной карман под пластиковые карты',
				sub_description: "",
				weight: "Вес ~ 150 гр."
			},
			slider_image: [
				{ image: bag7 },
				{ image: bag72 },
				{ image: bag73 },
				{ image: bag74 },
				{ image: bag75 },
				{ image: bag76 },
			],
		},
		{},
		{},
		{},
	],
	slider: {
		image: '',
		index: 0,
	},
	selected_model: [
		{}
	],
	cart_list: [],
	model_count: 0,
	cardId: 0,
	result_summ: 0,
	added_product: "Header_card_info_close__Kt69q",
	check_open_added_product: true,
	card_link: '',
	link: '/catalog',
}
const catalogReducer = (state = initialState, action) => {

	switch (action.type) {
		case SET_MODEL:
			state.selected_model = action.newSelectedModel;
			localStorage.setItem("card", JSON.stringify(state.selected_model));
			return state;
		case SET_CART_LIST:
			state.cart_list.push(action.newCartItem);
			localStorage.setItem("cart_list", JSON.stringify(state.cart_list));
			return state;
		case GET_LOCALE_STORAGE:
			JSON.parse(localStorage.getItem("card"));
			return state;
		case PLUS_MODEL_COUNT:
			state.model_count = action.newModelCount;
			localStorage.setItem("cardCount", JSON.stringify(state.model_count));
			return state;
		case PLUS_CART_MODEL:
			state.cart_list[action.newCardIdArray].count = action.newCount;
			localStorage.setItem("cart_list", JSON.stringify(state.cart_list));
			return state;
		case CARD_ID_PLUS:
			state.cardId = action.newCardId;
			localStorage.setItem("card_id", JSON.stringify(state.cardId));
			return state;
		case DELL_CART_ITEM:
			state.cart_list[action.newCardId].count = 1;
			state.cart_list.splice(action.newCardId, 1);
			localStorage.setItem("cart_list", JSON.stringify(state.cart_list));
			return state;
		case RESULT_SUMM_FUNC:
			state.result_summ = action.newSumm;
			return state;
		case OPEN_ADDED_PRODUCT:
			state.added_product = action.new_classe_added_product;
			state.check_open_added_product = action.check;
			return state;
		case DOUBLE_ADD_MODEL:
			state.cart_list[action.newCard].count = action.newCountList;
			localStorage.setItem("cart_list", JSON.stringify(state.cart_list));
			return state;
		case CHANGE_COLOR_IMAGE:
			state.bags_model[action.id].card_image = action.new_card_image;
			state.bags_model[action.id].card_image2 = action.new_card_image2;
			return state;
		case CHANGE_SLIDER_MAIN_IMAGE:
			state.slider.image = action.new_image;
			state.slider.index = action.new_index;
			localStorage.setItem("slider", JSON.stringify(state.slider));
			return state;
		case SET_LINK:
			state.card_link = action.new_link;
			return state;
		default:
			return state;
	}
}
export const setSelectedModelAC = (object_model) =>
	({ type: SET_MODEL, newSelectedModel: object_model })
export const setCartListAC = (cart_item) =>
	({ type: SET_CART_LIST, newCartItem: cart_item })
export const getLocaleStorageAC = () =>
	({ type: GET_LOCALE_STORAGE })
export const plusModelCountAC = (count_card) =>
	({ type: PLUS_MODEL_COUNT, newModelCount: count_card })
export const plusCartCountAC = (count_cart, idArr) =>
	({ type: PLUS_CART_MODEL, newCount: count_cart, newCardIdArray: idArr })
export const cardIdPlusAC = (cardIdCount) =>
	({ type: CARD_ID_PLUS, newCardId: cardIdCount })
export const delCardIdAC = (cardIdCount) =>
	({ type: DELL_CART_ITEM, newCardId: cardIdCount })
export const resultSummFuncAC = (summ) =>
	({ type: RESULT_SUMM_FUNC, newSumm: summ })
export const openAddedProductAC = (new_classe, new_check) =>
	({ type: OPEN_ADDED_PRODUCT, new_classe_added_product: new_classe, check: new_check })
export const doubleAddModelAC = (card_id, new_count) =>
	({ type: DOUBLE_ADD_MODEL, newCard: card_id, newCountList: new_count })
export const changeColorImageAC = (id, new_image, new_image2) =>
	({ type: CHANGE_COLOR_IMAGE, id: id, new_card_image: new_image, new_card_image2: new_image2 })
export const changeSliderMainImageAC = (new_image, index) =>
	({ type: CHANGE_SLIDER_MAIN_IMAGE, new_image: new_image, new_index: index })
export const setLinkAC = (link) =>
	({ type: SET_LINK, new_link: link })
export default catalogReducer;