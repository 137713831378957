import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import s from "./Card.module.css";

const Card = (props) => {
	let style_price_line;
	let style_price_active;
	if (props.price_active === "") {
		style_price_line = {
			textDecoration: "none",
			margin: 0
		}
		style_price_active = {
			display: "none"
		}
	}
	const [isShownHoverContent, setIsShownHoverContent] = useState(true);
	let setModelCart = () => {
		if (props.link === "/catalog") {
			change_new_image();
			props.setSelectedModel(props.model);
		} else if (props.link === "/sertificates") {
			debugger
			props.set_sertificate(
				props.id,
				props.card_name,
				props.price,
				props.card_image,
				props.code[0].code
			);
			props.setSelectedModel(props.model);
		}
	};
	let plusCount = () => {
		let count = props.modelCount;
		count++;
		props.plusModelCount(count);
	};

	let setCart = () => {
		props.setCartList(props.model);
		setModelCart();
		Open_buy_card();
		plusCount();
	};
	let Open_buy_card = () => {
		if (props.check_open_added_product) {
			props.newClassAddedProduct("Header_card_info__1wL8g", false);
			setTimeout(Close_buy_card, 5000);
		}
	};
	let Close_buy_card = () => {
		props.newClassAddedProduct("Header_card_info_close__Kt69q", true);
	};
	let change_new_image = () => {
		props.change_image(props.card_image, 0);
	};
	return (
		<div className={s.card}>
			<NavLink to={props.link + "/" + props.id}>
				<div
					className={s.image_div}
					onMouseEnter={() => setIsShownHoverContent(false)}
					onMouseLeave={() => setIsShownHoverContent(true)}
					onClick={setModelCart}
				>
					{isShownHoverContent && (
						<img src={props.card_image} className={s.card_image} alt="" />
					)}
					<img src={props.card_image2} className={s.card_image2} alt="" />
				</div>
				<div className={s.card_description}>
					<div className={s.description_left}>
						<div className={s.card_name}>{props.card_name}</div>
						<div className={s.card_price_wrapper}>
							<span className={s.card_price_active} style={style_price_active}>{props.price_active}₽</span>
							<span className={s.card_price} style={style_price_line}>{props.price}₽</span>
						</div>
					</div>
					{/*<button className={s.card_button} onClick={setModelCart}>
						ПЕРЕЙТИ
					</button>*/}
					{/*<button className={s.card_button} onClick={(setCart)}>В КОРЗИНУ</button>*/}
				</div>
			</NavLink>
		</div>
	);
};
export default Card;
